<template>
	<div v-if="title || elements.length" class="c-accordion-layout">
		<div class="w-full flex justify-between items-end mb-xl">
			<div class="w-5/5col >=1024:w-5/12col flex-shrink-0">
				<slot name="title">
					<BaseH2 v-if="title" tag="h2" v-text="title" />
				</slot>
			</div>

			<BaseButton
				light
				class="hidden >=1024:block"
				@click="() => toggleAll()"
			>
				<template #default>
					<div v-text="isGroupOpen ? 'Luk alle' : 'Åben alle'"></div>
				</template>

				<template #icon>
					<SvgIconCaret
						:class="[
							'mt-1',
							'transform duration-300 ease-smooth-out',
							{ '-rotate-180 -mt-1': isGroupOpen },
						]"
					/>
				</template>
			</BaseButton>
		</div>

		<AccordionGroup
			v-bind="{ tag, minOneOpen, maxOneOpen }"
			ref="accordionGroup"
			class="overflow-hidden"
		>
			<div
				v-for="(item, index) in elements"
				:key="`item-${index}`"
				class="c-accordion-layout__item relative"
			>
				<!-- Headers -->
				<AccordionHeader
					:id="`accordion-${item.key}` || `header-${_uid}-${index}`"
					:key="`header-${_uid}-${index}`"
					:aria-controls="`panel-${_uid}-${index}`"
					:class="[
						'c-accordion-layout__header',
						'relative flex justify-between items-center',
						'py-lg w-full group bg-secondary',
						'duration-300 ease-smooth-out',

						{
							'border-t-2 border-white': index !== 0,
							'bg-opacity-50': panelsOpen[index],
						},
					]"
				>
					<div class="flex justify-between flex-1 px-xl max-w-full">
						<div
							:class="[
								'c-accordion-layout__header-content',
								'transform pr-lg',
								'duration-300 ease-smooth-out',

								{
									'group-hover:translate-x-xs':
										!panelsOpen[index],
								},
							]"
						>
							<slot
								name="header"
								v-bind="{
									item,
									index,
									isActive: panelsOpen[index],
								}"
							></slot>
						</div>

						<SvgIconCaret
							:class="[
								'w-20 transform flex-shrink-0',
								'duration-300 ease-smooth-out',
								{ '-rotate-180': panelsOpen[index] },
							]"
						/>
					</div>
				</AccordionHeader>

				<!-- Panels -->
				<AccordionPanel
					:id="`panel-${_uid}-${index}`"
					:ref="`panel-${index}`"
					:key="`panel-${_uid}-${index}`"
					:transition="{ name: 't-accordion-layout__panel' }"
					@change="(event) => onPanelChange(event, index)"
				>
					<div
						:class="[
							'c-accordion-layout__panel',
							'px-xl pt-lg pb-4xl bg-secondary',
							'duration-300 ease-smooth-out',

							{
								'bg-opacity-50': panelsOpen[index],
							},
						]"
					>
						<slot
							name="panel"
							v-bind="{
								item,
								index,
								isActive: panelsOpen[index],
							}"
						></slot>
					</div>
				</AccordionPanel>
			</div>
		</AccordionGroup>
	</div>
</template>

<script>
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import SvgIconCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'AccordionLayout',

	components: {
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		SvgIconCaret,
	},

	provide: {
		headingLevel: 3,
	},

	props: {
		...AccordionGroup.props,

		title: {
			type: String,
			required: false,
		},

		elements: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			panelsOpen: this.elements.map(() => false),
			isGroupOpen: false,
		};
	},

	mounted() {
		if (window.location.hash) {
			try {
				const el = document.querySelector(window.location.hash);
				el?.scrollIntoView(true);
			} catch (err) {
				console.error(err);
			}
		}
	},

	methods: {
		toggleAll(force = null) {
			if (this.$refs.accordionGroup) {
				const { accordionGroup: ag } = this.$refs;
				const state = force !== null ? force : !this.isGroupOpen;
				!state ? ag.closeAll() : ag.openAll();
			}
		},
		openPanel(index) {
			if (this.$refs[`panel-${index}`][0]?.open) {
				this.$refs[`panel-${index}`][0].open();
			}
		},
		onPanelChange(event, index) {
			this.$set(this.panelsOpen, index, event.isOpen);
			this.$emit('change', { index, state: event.isOpen });

			this.isGroupOpen =
				this.panelsOpen.reduce(
					(acc, cur) => (cur ? acc + 1 : acc),
					0
				) >= 2;
		},
	},
};
</script>

<style lang="postcss">
.c-accordion-layout {
	& :where(.c-block-list__block:not(:first-child)) {
		@apply mt-4xl;
	}

	&
		:where(.c-block-list-rich-text-editor-custom
			+ .c-block-list-cta-button-custom) {
		@apply mt-xl;
	}
}

.c-accordion-layout__header-content {
	word-break: break-word;
}

.c-accordion-layout__panel h3,
.c-accordion-layout__panel h4 {
	@apply text-h3;
}

.t-accordion-layout__panel-enter-active,
.t-accordion-layout__panel-leave-active {
	transition: max-height 300ms cubic-bezier(0.5, 0.035, 0.19, 1);
}

.t-accordion-layout__panel-enter,
.t-accordion-layout__panel-leave-to {
	max-height: 0;
}
</style>
