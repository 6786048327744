var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || _vm.elements.length)?_c('div',{staticClass:"c-accordion-layout"},[_c('div',{staticClass:"w-full flex justify-between items-end mb-xl"},[_c('div',{staticClass:"w-5/5col >=1024:w-5/12col flex-shrink-0"},[_vm._t("title",function(){return [(_vm.title)?_c('BaseH2',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e()]})],2),_vm._v(" "),_c('BaseButton',{staticClass:"hidden >=1024:block",attrs:{"light":""},on:{"click":() => _vm.toggleAll()},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"textContent":_vm._s(_vm.isGroupOpen ? 'Luk alle' : 'Åben alle')}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgIconCaret',{class:[
						'mt-1',
						'transform duration-300 ease-smooth-out',
						{ '-rotate-180 -mt-1': _vm.isGroupOpen },
					]})]},proxy:true}],null,false,3096733858)})],1),_vm._v(" "),_c('AccordionGroup',_vm._b({ref:"accordionGroup",staticClass:"overflow-hidden"},'AccordionGroup',{ tag: _vm.tag, minOneOpen: _vm.minOneOpen, maxOneOpen: _vm.maxOneOpen },false),_vm._l((_vm.elements),function(item,index){return _c('div',{key:`item-${index}`,staticClass:"c-accordion-layout__item relative"},[_c('AccordionHeader',{key:`header-${_vm._uid}-${index}`,class:[
					'c-accordion-layout__header',
					'relative flex justify-between items-center',
					'py-lg w-full group bg-secondary',
					'duration-300 ease-smooth-out',

					{
						'border-t-2 border-white': index !== 0,
						'bg-opacity-50': _vm.panelsOpen[index],
					},
				],attrs:{"id":`accordion-${item.key}` || `header-${_vm._uid}-${index}`,"aria-controls":`panel-${_vm._uid}-${index}`}},[_c('div',{staticClass:"flex justify-between flex-1 px-xl max-w-full"},[_c('div',{class:[
							'c-accordion-layout__header-content',
							'transform pr-lg',
							'duration-300 ease-smooth-out',

							{
								'group-hover:translate-x-xs':
									!_vm.panelsOpen[index],
							},
						]},[_vm._t("header",null,null,{
								item,
								index,
								isActive: _vm.panelsOpen[index],
							})],2),_vm._v(" "),_c('SvgIconCaret',{class:[
							'w-20 transform flex-shrink-0',
							'duration-300 ease-smooth-out',
							{ '-rotate-180': _vm.panelsOpen[index] },
						]})],1)]),_vm._v(" "),_c('AccordionPanel',{key:`panel-${_vm._uid}-${index}`,ref:`panel-${index}`,refInFor:true,attrs:{"id":`panel-${_vm._uid}-${index}`,"transition":{ name: 't-accordion-layout__panel' }},on:{"change":(event) => _vm.onPanelChange(event, index)}},[_c('div',{class:[
						'c-accordion-layout__panel',
						'px-xl pt-lg pb-4xl bg-secondary',
						'duration-300 ease-smooth-out',

						{
							'bg-opacity-50': _vm.panelsOpen[index],
						},
					]},[_vm._t("panel",null,null,{
							item,
							index,
							isActive: _vm.panelsOpen[index],
						})],2)])],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }